<template>
  <div v-if="isshowgrid">
    <va-card title="Support Setting List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button v-if="admin_role" color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Organization Name</span>
            <va-select
              v-if="isCreate"              
              placeholder="Select Operator Organization"
              v-model="support_setting.operator_org_info"
              textBy="org_name"
              searchable
              :options="operator_org_obj"
              :error="!!operatorOrgInfoErrors.length"
              :error-messages="operatorOrgInfoErrors"
              noClear
            />
            <va-input
              v-if="isUpdate"
              v-model="support_setting.org_name"
              type="text"              
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Description</span>
            <va-input
              v-model="support_setting.description"
              type="text"
              pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"              
              placeholder="Enter Description"
              :error="!!descriptionErrors.length"
              :error-messages="descriptionErrors"
            />
            <span class="va-form-label">Fb_Link</span>
            <va-input
              v-model="support_setting.fb_link"
              placeholder="Enter FB Link"
              type="text"
              pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"              
              :error="!!fbLinkErrors.length"
              :error-messages="fbLinkErrors"
            />
            <span class="va-form-label">Youtube Link</span>
            <va-input
              v-model="support_setting.youtube_link"
              placeholder="Enter Youtube Link"
              pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
              type="text"                    
              :error="!!youtubeLinkErrors.length"
              :error-messages="youtubeLinkErrors"
            />
            <span class="va-form-label">Website Link</span>
            <va-input
              v-model="support_setting.website_link"
              pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
              type="text"              
              placeholder="Enter Website Link"
            />
            <span class="va-form-label va-form-required-label">Mobile number or Landline</span>
            <va-input
              v-model="support_setting.support_mobile_number"
              type="text"
              maxlength=15
              pattern="[6-9]{1}[0-9]{9}"              
              placeholder="Enter Support Mobile number"
              :error="!!supportMobileNumberErrors.length"
              :error-messages="supportMobileNumberErrors"
            />
            <span class="va-form-label va-form-required-label">Support Email Id</span>
            <va-input
              v-model="support_setting.support_email"
              pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{2,4}$"
              type="text"              
              placeholder="Enter Support Email Id"
              :error="!!supportEmailErrors.length"
              :error-messages="supportEmailErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" v-if="admin_role" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createSupportSettings()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateSupportSettings()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
  <div v-else-if="operator_role && hide_grid">
    <va-card><center>Please wait until the support setting created</center></va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'support_setting',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/org').then(response => {
      loader.hide()
      response.body.map(function (data, i) {
        data.id = i + 1
      })
      this.operator_org_obj = response.body.filter(function (entry) {
        return (entry.org_type != 'ADMIN')
      })
      this.isshowForm = false
      this.isshowgrid = true
      this.$http.get(config.menu.host + '/user/' + this.$cookies.get('user-id')).then(resp => {
        this.Role = resp.body.roles
        this.OrgId = resp.body.org_id
        var roles = resp.body.roles
        var org_create_provision = this.$cookies.get('org_create_provision')
        if (roles.includes('ADMIN')) {
          this.operator_role = false
          this.admin_role = true
          this.show_create_flag = true
        } else if (roles.includes('OPERATOR')) {
          this.operator_role = true
          this.admin_role = false
        } else if (roles.includes('RESELLER')) {
          this.operator_role = false
          this.admin_role = false
          this.mso_role = true
          if (org_create_provision) {
            this.admin_role = true
            this.show_create_flag = true
          } 
        }   
        if(roles.includes('SUPPORT')){
          this.supportRole=true;
        } 
        this.getSupportSettings()
      })
    }, error => {
      loader.hide()
      if (error && error.body) {
        Vue.notify({ text: error.body, type: 'error' })
      }
    })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      mso_role: false,
      hide_grid: true,
      operator_role:false,
      admin_role:false,
      reseller_role:false,
      title: '',
      Role: '',
      OrgId: '',
      User: [],
      operator_org_obj: [],
      descriptionErrors: [],
      fbLinkErrors: [],
      youtubeLinkErrors: [],
      supportEmailErrors: [],
      supportSettingData: [],
      operatorOrgInfoErrors: [],
      supportMobileNumberErrors: [],
      support_setting: {
        operator_org_info: {},
        description: '',
        youtube_link: '',
        fb_link: '',
        website_link: '',
        support_mobile_number: '',
        support_email: '',
        org_name: '',
        org_id: '',
      },
      supportRole:false
    }
  },
  computed: {
    formReady () {
      return !this.operatorOrgInfoErrors.length && !this.descriptionErrors.length && !this.supportEmailErrors.length && !this.supportMobileNumberErrors.length
    },
    fields () {
      return [
        {
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        },
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'description',
          title: 'Description',
        },
        {
          name: 'website_link',
          title: 'Website Link',
        },
        {
          name: 'support_mobile_number',
          title: 'Support Mobile Number',
        },
        {
          name: 'support_email',
          title: 'Support Email ID',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    filteredData () {
      return search(this.term, this.supportSettingData)
    },
  },
  methods: {
    getSupportSettings () {
      this.$http.get(config.menu.host + '/support_setting/' + this.Role + '/' + this.OrgId).then(response => {
        this.isshowgrid = true
        if (this.admin_role || this.mso_role || this.supportRole) {
          let index = 0
          this.supportSettingData = response.body.map(function (item) {
              item.id = index++
              item.sno = index
              item.is_existing = true
              item.checkbox_value = false
              return item
          })
        }
        if(this.admin_role || this.reseller_role){
          let index = 0
          this.supportSettingData = response.body.map(function (item) {
              item.id = index++
              item.sno = index
              item.is_existing = true
              item.checkbox_value = false
              return item
          })
        }else if(this.operator_role){
          this.isshowgrid = false
          if (response.body.length == 0) {
            this.hide_grid = true
          }
          if (response.body.length != 0) {
            this.edit(response.body[0])
          }
        }
      });
    },
    createSupportSettings () {
      this.support_setting.org_name = this.support_setting.operator_org_info.org_name
      this.support_setting.org_id = this.support_setting.operator_org_info.org_id
      this.support_setting.reseller_org_id = this.support_setting.operator_org_info.reseller_org_id
      this.operatorOrgInfoErrors = this.support_setting.operator_org_info ? [] : ['Operator Organization is required']
      this.descriptionErrors = this.support_setting.description ? [] : ['Description is required']
      this.supportMobileNumberErrors = this.support_setting.support_mobile_number ? [] : ['Support Mobile Number is required']
      this.supportEmailErrors = this.support_setting.support_email ? [] : ['Support Email is required']
      delete this.support_setting.operator_org_info
      if (!this.formReady) {
        return
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/support_setting', this.support_setting).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateSupportSettings () {
      if(this.support_setting.operator_org_info == undefined){
        this.support_setting.operator_org_info = {}
      }
      this.operatorOrgInfoErrors = this.support_setting.operator_org_info ? [] : ['Operator Organization is required']
      this.descriptionErrors = this.support_setting.description ? [] : ['Description is required']
      this.supportMobileNumberErrors = this.support_setting.support_mobile_number ? [] : ['Support Mobile Number is required']
      this.supportEmailErrors = this.support_setting.support_email ? [] : ['Support Email is required']
      if (!this.formReady) {
        return
      }
      delete this.support_setting.operator_org_info
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/support_setting', this.support_setting).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = 'Create Support Setting'
      this.support_setting= {
        operator_org_info: {},
        description: '',
        youtube_link: '',
        fb_link: '',
        website_link: '',
        support_mobile_number: '',
        support_email: '',
        org_name: '',
        org_id: '',
      }
      this.descriptionErrors=  []
      this.fbLinkErrors=  []
      this.youtubeLinkErrors=  []
      this.supportEmailErrors=  []
      this.supportSettingData=  []
      this.operatorOrgInfoErrors=  []
      this.supportMobileNumberErrors=  []
      this.support_setting.operator_org_obj = {}
      this.support_setting.description = ''
      this.support_setting.youtube_link = ''
      this.support_setting.fb_link = ''
      this.support_setting.website_link = ''
      this.support_setting.support_mobile_number = ''
      this.support_setting.support_email = ''
      this.support_setting.org_name = ''
      this.support_setting.org_id = ''
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.title = 'Update Support Setting'
      this.support_setting.org_id = row.org_id
      this.support_setting.org_name = row.org_name
      this.support_setting.description = row.description
      this.support_setting.fb_link = row.fb_link
      this.support_setting.youtube_link = row.youtube_link
      this.support_setting.website_link = row.website_link
      this.support_setting.support_mobile_number = row.support_mobile_number
      this.support_setting.support_email = row.support_email
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getSupportSettings()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
